// NPM
import React from 'react'

// Components
import Description from 'src/components/Shared/Description'

// Constants
const icons = {
  "infoColumns_reason": require("src/images/icons/reason-icon.png").default, 
  "infoColumns_insights": require("src/images/icons/insights-icon.png").default  
}

const InfoColumns = ({ sections }) => {

  const renderColumn = section => {
    if(!section) {
      return null
    }

    return (
      <div className="InfoColumns__column">
        <div className="InfoColumns__column-icon">
          <img src={ icons[section.uuid] } aria-hidden="true" />
        </div>

        <h4 className="InfoColumns__column-title">
          { section.title }
        </h4>

        <Description 
          className="InfoColumns__column-description"
          description={ section.description }
        />
      </div>
    )
  }

  const reasonColumn = sections.find(s => s.uuid === "infoColumns_reason")
  const insightsColumn = sections.find(s => s.uuid === "infoColumns_insights")

  if(reasonColumn && insightsColumn) {
    return (
      <div className="InfoColumns">
        { renderColumn(reasonColumn) }
        { renderColumn(insightsColumn) }
      </div>
    )  
  }
  else {
    return null
  }

}

export default InfoColumns