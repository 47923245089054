// NPM
import React from 'react'
import { Link } from 'gatsby'
import _ from 'lodash'

// Shared components
import Description from 'src/components/Shared/Description'

const WorkTogether = ({ section }) => {

  if(!section) {
    return null;
  }

  return (
    <div className="WorkTogether">
      <div className="WorkTogether__container">
        <h3 className="WorkTogether__title">
          { section.title }
        </h3>
        
        <Description
          className="WorkTogether__description"
          description={ section.description }
        />

        { 
          section.link &&
            <Link
              to={ _.get(section.link, "page.path", "/") }
              className="WorkTogether__cta black-button">
                <span>{ section.link.text }</span>
                <span className="icon">
                  <img src={ require("src/images/icons/arrow-right-white.png").default } />
                </span>
            </Link>
        }
      </div>
    </div>
  )

}

export default WorkTogether;