// NPM
import React from 'react'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';

// Shared components
import Description from 'src/components/Shared/Description'

const Timeline = ({ section }) => {
  
  if(!section) {
    return null
  }

  return (
    <div className="Timeline">
      <h4 className="Timeline__title small-header">
        { section.title }
      </h4>

      <VerticalTimeline lineColor="#004E5F">
        {
          section.timeline.map((timelineEntry, timelineEntryIndex) => {
            return (
              <VerticalTimelineElement 
                key={ `Timeline-entry-${ timelineEntryIndex }` }
                iconClassName="Timeline__entry-icon">
                  <div className="Timeline__entry-indicator">
                    { timelineEntry.indicator }
                  </div>

                  <div className="Timeline__entry-title">
                    { timelineEntry.title }
                  </div>

                  <Description
                    className="Timeline__entry-description"
                    description={ timelineEntry.description }
                  />
              </VerticalTimelineElement>
            )
          })
        }
      </VerticalTimeline>
    </div>
  )
}

export default Timeline